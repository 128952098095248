<template>
  <div style="text-align:center;color:orange;padding:20px;">
    <b>
      <h2>
      Debe estar logueado para realizar la solicitud / You must be logged in to submit the request
      </h2>
    </b>
  </div>
</template>
<script>
export default {
  name:'Item3Space',
  props: {
    content: {
      type: Object,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    isDesktop: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

