<template>
  <div v-if="contentTypes">
    <div
      v-for="item of contents"
      :key="item.ID"
      :style="{ 
        opacity: item.HideMobile === 1 ? '20%' : null,
        border: dtouchSimulationShowFastEdit ? '1px dashed #cccccc' : null,
        margin: dtouchSimulationShowFastEdit ? '1px 0' : null
      }"
      style="border-style: dashed !important;"
    >
      <div v-if="dtouchSimulationShowFastEdit">
        <v-layout wrap>
          <v-flex xs8 style="padding: 5px;">
            <small style="opacity: 50%;padding:5px;background-color: white;">
              {{getContentTypeName(item.Type)}} [{{item.Type}}]
            </small>
          </v-flex>
          <v-flex xs4 style="text-align: right;padding: 5px 5px 0 0 ;">
        <v-btn fab x-small elevation="0"
          v-if="item.ID !== 'new'"
          @click.stop.prevent="onEditItem(false, item.ID, item.MenuID)"
          style="margin-right: 5px;"
        >
            <v-icon
              small
              color="primary"
              block
            >
              mdi-pencil
            </v-icon>
          </v-btn>
        <v-btn fab x-small elevation="0"
          v-if="item.ID !== 'new'"
        >
            <delete-item
              v-if="item.ID !== 'new'"
              :id="item.ID"
              :onDeleteItem="handleDeleteContent"
              icon="mdi-delete"
              :locale="locale"
            />
          </v-btn>
          </v-flex>
        </v-layout>
      </div>
    <div
      v-if="item.HideMobile !=1 || dtouchSimulationShowContentHide"
      :style="{
        padding: item.Padding
      }"
    >
      <item-2-simple-text
        v-if="item.Type === 2"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-3-space
        v-else-if="item.Type === 3"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-4-image
        v-else-if="item.Type === 4"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-5-iframe
        v-else-if="item.Type === 5"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-6-alert
        v-else-if="item.Type === 6"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-7-gallery
        v-else-if="item.Type === 7"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :isSimulation="true"
      />
      <item-9-meteo
        v-else-if="item.Type === 9"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :isSimulation="true"
      />
      <item-13-activities
        v-else-if="item.Type === 13"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :isSimulation="true"
      />
      <item-16-text
        v-else-if="item.Type === 16"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-17-contact
        v-else-if="item.Type === 17"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-18-divider
        v-else-if="item.Type === 18"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-19-show
        v-else-if="item.Type === 19"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-20-site
        v-else-if="item.Type === 20"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-22-parrilla
        v-else-if="item.Type === 22"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-27-food
        v-else-if="item.Type === 27"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-28-link-domain
        v-else-if="item.Type === 28"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-30-product-service
        v-else-if="item.Type === 30"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />

      <item-31-pdf-translate
        v-else-if="item.Type === 31"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-32-link-external
        v-else-if="item.Type === 32"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-33-channel-list
        v-else-if="item.Type === 33"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-34-acttiv
        v-else-if="item.Type === 34"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-35-poll
        v-else-if="item.Type === 35"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-37-icon-list
        v-else-if="item.Type === 37"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-43-icons
        v-else-if="item.Type === 43"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-45-brochure
        v-else-if="item.Type === 45"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-47-book-my-bookings
        v-else-if="item.Type === 47"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-48-book-activities
        v-else-if="item.Type === 48"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-49-menu-ext-food
        v-else-if="item.Type === 49"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-51-weekly-menu
        v-else-if="item.Type === 51"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-52-requests
        v-else-if="(item.Type === 52 || item.Type === 53)"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :type="item.Type"
      />
      <!--item-52-requests
        v-else-if="item.Type === 52"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      />
      <item-53-issues
        v-else-if="item.Type === 53"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
      /-->
      <item-55-dviewcontent-messages
        v-else-if="(item.Type === 55)"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :type="item.Type"
      />
      <item-57-interactive-maps
        v-else-if="(item.Type === 57)"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :type="item.Type"
      />
      <item-58-abama-request-reservation
        v-else-if="(item.Type === 58)"
        :content="item.Content"
        :config="config"
        :locale="locale"
        :isDesktop="isDesktop"
        :type="item.Type"
      />
      <v-alert
        v-else
        border="right"
        colored-border
        type="error"
        elevation="2"
      >
      TODO: {{ getContentTypeName(item.Type) }} [{{item.Type}}]
      </v-alert>
    </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import api from '@/services/api'
import Item2SimpleText from './Item2SimpleText'
import Item3Space from './Item3Space'
import Item4Image from './Item4Image'
import Item5Iframe from './Item5Iframe'
import Item6Alert from './Item6Alert'
import Item7Gallery from './Item7Gallery'
import Item9Meteo from './Item9Meteo'
import Item16Text from './Item16Text'
import Item13Activities from './item13Activities/Index'
import Item17Contact from './item17Contact/Index'
import Item18Divider from './Item18Divider'
import Item19Show from './item19Show/Index'
import Item20Site from './Item20Site'
import Item22Parrilla from './item22Parrilla/Index'
import Item27Food from './item27Food/Index'
import Item28LinkDomain from './Item28LinkDomain'
import Item30ProductService from './item30ProductService/Index'
import Item31PdfTranslate from './item31PDFTranslate/Index'
import Item32LinkExternal from './Item32LinkExternal'
import Item33ChannelList from './Item33ChannelList'
import Item34Acttiv from './item34Acttiv/Index'
import Item35Poll from './item35Poll/Index'
import Item37IconList from './Item37IconList'
import Item43Icons from './Item43Icons'
import Item45Brochure from './Item45Brochure'
import Item47BookMyBookings from './item47BookMyBookings/Index'
import Item48BookActivities from './item48BookActivities/Index'
import Item49MenuExtFood from './item49MenuExtFood/Index'
import Item51WeeklyMenu from './item51WeeklyMenu/Index'
import Item52Requests from './item52Requests/Index'
import Item55DviewcontentMessages from './Item55DviewcontentMessages'
//import Item53Issues from './item53Issues/Index'
import Item57InteractiveMaps from './item57InteractiveMaps/Index'
import Item58AbamaRequestReservation from './Item58AbamaRequestReservation'
import DeleteItem from '@/components/DeleteItem'
export default {
  name: 'EditedContent',
  components: {
    DeleteItem,
    Item2SimpleText,
    Item3Space,
    Item4Image,
    Item5Iframe,
    Item6Alert,
    Item7Gallery,
    Item9Meteo,
    Item13Activities,
    Item16Text,
    Item17Contact,
    Item18Divider,
    Item19Show,
    Item20Site,
    Item22Parrilla,
    Item27Food,
    Item28LinkDomain,
    Item30ProductService,
    Item31PdfTranslate,
    Item32LinkExternal,
    Item33ChannelList,
    Item34Acttiv,
    Item35Poll,
    Item37IconList,
    Item43Icons,
    Item45Brochure,
    Item47BookMyBookings,
    Item48BookActivities,
    Item49MenuExtFood,
    Item51WeeklyMenu,
    Item52Requests,
    Item55DviewcontentMessages,
    //Item53Issues,
    Item57InteractiveMaps,
    Item58AbamaRequestReservation,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    config: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    onEditItem: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    contents: [],
    isDesktop: false,
    contentTypes: null,
    isFastEdit: true,
  }),
  computed: {
    ...mapState('app',['dtouchContentEdit', 'dtouchSimulationShowContentHide', 'dtouchSimulationShowFastEdit']),
  },
  watch: {
    id (v) {
      if (v) this.getContents()
    },
    'dtouchContentEdit'(v) {
      if (v) {
        if(v.ID === 'new') this.addNewContent(v)
        this.updateContent(v)
      }
    },
  },
  mounted () {
    this.getContentTypes()
    if (this.id) this.getContents()
  },
  methods: {
    getContentTypes () {
      api.getAllWithoutLimit ('dtouch', 'v1/private/contents/content-types')
        .then((response) => {
          this.contentTypes = response
        })
    },
    getContentTypeName (v) {
      return this.$t(`contentType.${this.contentTypes.filter(x => x.id === v).shift().name}`)
    },
    addNewContent (v) {
      if (this.contents.filter(x => x.ID === v.ID).length === 0) {
        this.contents.push({
          ID: v.ID,
          Type: v.Type,
          Content: v
        })
      }
    },
    updateContent (v) {
      this.contents.filter(x => x.ID === v.ID).map(item => {
        item.Content = v
        if (v.Padding) item.Padding = v.Padding // update position content
        return item
      })
    },
    getContents () {
      api.getAllWithoutLimit ('dtouch', `v1/public/menus/${this.id}/contents/`)
        .then((response) => {
          this.contents = response
        })
    },
    handleDeleteContent (v) {
      api.deleteItem('dtouch', `v1/private/contents/`, v)
        .then(response => {
          if(response && response.data) {
            // update all content draggable menu
            const aux = this.contents.filter(x => x.ID === v).shift()
            if (aux && aux.MenuID)
              this.$store.state.app.dtouchDraggableMenuUpdated = {
                MenuID: aux.MenuID,
                LMD: new Date(),
              }
            // get new content situation
            this.getContents()
          }
        })
    },
  }
}
</script>

